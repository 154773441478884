// Javascript
import Swiper, { Autoplay, Navigation, Pagination, Thumbs, EffectFade } from 'swiper';
import ScrollOut from 'scroll-out';
import MicroModal from 'micromodal';
import bodymovin from 'lottie-web';  

// Scripts to fire once the DOM has loaded
document.addEventListener("DOMContentLoaded", () => {


    // Mobile navigation (global)
    var nav = document.querySelector('[data-nav]');
    var navTrigger = document.querySelector('[data-nav-trigger]');

    if(navTrigger){
        navTrigger.addEventListener('click', function() {
            navTrigger.classList.toggle('burger-icon--active');
            nav.classList.add('mobile--transition');
            nav.classList.toggle('mobile--active');
            document.body.classList.toggle('overflow-hidden');
        });
    }

    // Scroll effects (global)
    ScrollOut({
        targets: '[data-scroll]',
        threshold: 0.4,
        cssProps: {
            visibleY: true,
            intersectY: true,
            viewportY: true
        }
    });

    // Scroll effets for home page only
    ScrollOut({
        targets: '[data-home-scroll]',
        offset: 0,
        onShown: function(element, ctx, scrollingElement) {
            document.querySelector('[data-home-graphic]').classList.add('home-graphic--active');
        },
        onHidden: function(element, ctx, scrollingElement) {
            document.querySelector('[data-home-graphic]').classList.remove('home-graphic--active');
        },
    });


    // Modals (global)
    MicroModal.init({
        awaitCloseAnimation: true
    });


    // Gallery block modal
    const galleryContainer = document.querySelector('[data-gallery]');

    if (galleryContainer) {
        const observer = new MutationObserver(() => {
            const modalGalleryTriggers = document.querySelectorAll('[data-modal-gallery-trigger]');
            if(modalGalleryTriggers.length) {
                modalGalleryTriggers.forEach(function(trigger) {
                    trigger.addEventListener('click', function(e) {
                        const slideNumber = this.getAttribute('data-modal-gallery-position');
                        MicroModal.show('modal-gallery', {
                            awaitCloseAnimation: true,
                            onClose: modal => resetVideo(modal),
                        })
                        modalGallery.slideTo(slideNumber,0);
                    })
                })
                observer.disconnect();
            }
        })
        observer.observe(galleryContainer, { subtree: true, childList: true });
    }


    // Videos
    const videos = document.querySelectorAll('[data-video]');
    if(videos.length) {

        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.async = true;
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // window.onYouTubeIframeAPIReady = function() {
            videos.forEach(function(video) {
                video.addEventListener('click', function(e) {

                    var videoEl = this;
                    var videoID = this.getAttribute('data-video');
                    var iframe = document.createElement('div');
                    videoEl.append(iframe);
                    
                    var player;
                    player = new YT.Player(iframe, {
                        height: '360',
                        width: '640',
                        videoId: videoID,
                        playerVars: {
                            'modestbranding': 0,
                            'playsinline': 1
                        },
                        events: {
                            'onReady': playVideo
                        }
                    });
                })
            })
        // }
    }

    function playVideo(event) {
        event.target.playVideo();
    }

    function resetVideo(modal) {
        const iframe = modal.querySelector('iframe');
        if (iframe){
            iframe.remove();
        }
    }



    // Accordions
    // Used globally on mobile navigation, footer and accordion block
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.querySelector('[data-accordion-link]').firstElementChild;
            accordionLink.addEventListener('click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Gallery block carousel
    Swiper.use([Navigation, Pagination]);

    const gallery = new Swiper('[data-gallery]', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
            nextEl: '[data-gallery-next]',
            prevEl: '[data-gallery-prev]',
        },
        breakpoints: {
            1024: {
                centeredSlides: true,
            }
        }
    });


    // Gallery block modal carousel
    const modalGallery = new Swiper('[data-modal-gallery]', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        on: {
            slideChange: function() {
                resetVideo(this.el)
            }
        },
        navigation: {
            nextEl: '[data-modal-gallery-next]',
            prevEl: '[data-modal-gallery-prev]',
        },
        pagination: {
            el: '[data-modal-gallery-pagination]',
            type: 'fraction',
        }
    });


    // Tiles block carousel
    const tiles = new Swiper('[data-tiles]', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
            nextEl: '[data-tiles-next]',
            prevEl: '[data-tiles-prev]',
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
            }
        }
    });


    // Lottie graphic for landing pages
    const lottieGraphic = document.getElementById('landing-graphic');

    const animation = bodymovin.loadAnimation({
        container: lottieGraphic,
        path: 'img/landing-graphic/graphic.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    animation.play();
});